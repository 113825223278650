<template>
  <div>
    <Header />
    <v-row>
      <v-col class="contato">
        <div>Entre em Contato</div>
        <a
          href="https://wa.me/+5544999924781"
          target="_blank"
        >
        <img :src="require('@/assets/whatsapp.png')" width="200"/>
        </a>
      </v-col>
    </v-row>
    <v-row>
      <v-col />
      <v-col class="cards">
        <img :src="require('@/assets/comprarnfc.png')" width="500"/>
      </v-col>
      <v-col class="texto">
        <h1 class="vermelho">COM CHIP</h1>
        <h3 class="preto">Por aproximação</h3>
        <h3 class="preto">e QRCode</h3>
        <h1 class="vermelho">R$ 99,90</h1>
      </v-col>
      <v-col />
    </v-row>
    <v-row>
      <v-col />
      <v-col class="cards">
        <img :src="require('@/assets/comprarqrcode.png')" width="300"/>
      </v-col>
      <v-col class="texto">
        <h1 class="vermelho">SEM CHIP</h1>
        <h3 class="preto">Somente QRCode</h3>
        <h1 class="vermelho">R$ 49,90</h1>
      </v-col>
      <v-col />
    </v-row>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/HeaderGeral.vue";
import Carousel from "../components/Carousel.vue";
import Footer from "../components/Footer.vue";

export default {
  components: {
    Header,
    Carousel,
    Footer,
  },
};
</script>
<style scoped>
.contato {
  font-size: 40px;
  font-weight: bold;
  margin-top: 100px;
  color: red;
}
.cards {
  margin-top: 10px;
  text-align: right;
}
.texto {
  margin-top: 50px;
}
.vermelho {
  color: red;
}
.index {
  z-index: 1;
}
</style>
